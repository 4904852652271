/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/named */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby';
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { navigate } from 'gatsby-link';
import CountUp from 'react-countup';
import IconButton from '@material-ui/core/IconButton';
import { myStyles } from '../signuppopup/styles';
import { themeStyles } from '../../styles';
import { AstContext, AstContextDispatch } from '../../utils/ast-context';
import Helper from './helper';
import RewardDisclaimer from './reward-disclaimer';
import ThemeContext from '../../utils/theme-context';
import { routerHandling } from './form-validation';

export default function SellPriveValue({ data, context }) {
  const classes = myStyles();
  const [currency] = React.useState('EUR');
  const astContext = useContext(AstContext);
  const dispatch = useContext(AstContextDispatch);
  const [propValue, setPropValue] = useState(0);
  const [cashbakCounter, setCashbakCounter] = useState(true);
  const [cashbackVisible, setCashbackVisible] = useState(false);
  const [helper, setHelper] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);
  const [cashback, setCashback] = useState(3500);
  const [nextDisable, setNextDisable] = useState(true);
  const [rewardTable] = useState(context.type ? context.rewardTable.childrenContentfulRewardTableRewardTiersJsonNode : context.rewardTableSell.childrenContentfulRewardTableRewardTiersJsonNode);
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const [lead, setLead] = useState({});
  const [LocState, setLocState] = useState(true);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      navigate('/ast');
    }
    const getLead = JSON.parse(localStorage.getItem('lead'));
    setLead(getLead);
    if (getLead) {
      if (getLead.buyState) {
        const location = getLead.buyState;
        if (location === 'OK' || location === 'AK') {
          setLocState(false);
        }
      }

      if (getLead.sellPropPrice) {
        setPropValue(getLead.sellPropPrice);
        setCashbackVisible(true);
        // setNextDisable(false);
      }
    }
  }, []);

  useEffect(() => {
    rewardTable.map((point) => {
      if (propValue == point.value || propValue == point.min) {
        setCashback(point.reward);
        setCashbackVisible(true);
        const timerValue = setTimeout(() => {
          setNextDisable(false);
        }, 2500);
        setTimer(timerValue);
      }
    });
  }, [propValue]);

  const updateAriaValues = () => {
    const ariaObj = document.getElementById('ada-only-text');
    if (ariaObj) {
      ariaObj.setAttribute('aria-live', 'polite');
      ariaObj.setAttribute('aria-atomic', 'true');
    }
  };

  const handlerClick = (path) => {
    dispatch({ type: 'SET_SELL_PROPERTY_VALUE', value: { ...lead, sellPropPrice: propValue, sellreward: cashback } });
    navigate(path);
  };

  const helperShow = () => {
    setHelper(true);
    setCashbakCounter(false);
  };

  const helperHide = () => {
    setHelper(false);
  };

  const showDisclaimer = () => {
    setDisclaimer(true);
    setCashbakCounter(false);
  };

  const hideDisclaimer = () => {
    setDisclaimer(false);
  };
  const updatepropValue = (value) => {
    clearTimeout(timer);
    setPropValue(value);
    setCashbakCounter(true);
    setNextDisable(true);
    updateAriaValues();
  };

  const closeModal = () => {
    localStorage.removeItem('lead');
    navigate('/');
  };
  const prevNavigation=() =>{
    const prevBtnUrl = routerHandling(context, "prev");
    navigate(`${prevBtnUrl}`)
  };
  return (
    <Container id="estimated-sell-value" className={classes.root}>
      <Grid container>
        {(helper && !disclaimer) && (
        <>
          <Helper helperData={data} contextData={context} helperSwitch={helperHide} />
        </>
        )}
        {(!helper && disclaimer) && (
        <>
          <RewardDisclaimer disclaimerData={context.rewardDisclaimerText} disclaimerSwitch={hideDisclaimer} title="Reward Disclaimer" />
        </>
        )}
        {(!helper && !disclaimer)
      && (
      <>
        <div className={classes.root}>
          <div className={classes.prev}>
          <IconButton aria-label="back" onClick={prevNavigation} className={classes.backPage}>
            <ArrowBackIcon />
          </IconButton>
            {data && data.showHelperText && (
              <Typography variant="caption">
                <div>
                  <Button onClick={helperShow} className={classes.haveQuestions}>
                    <ContactSupportIcon />
                    Have Questions
                  </Button>
                </div>
              </Typography>
            )}
            {/* <IconButton aria-label="close" className={classes.margin} onClick={closeModal}>
              <CloseIcon />
            </IconButton> */}
          </div>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper className={classes.paper}>
              <Typography variant="h2" className={classes.internalContent}>{data.question.internal.content}</Typography>
              {/* {data.showHelperText ? <Typography style={{ textAlign: 'center' }}>{data.helperText.internal.content}</Typography> : ''} */}
            </Paper>
          </Grid>
          <Grid container spacing={3} className={classes.amount}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                id="outlined-select-currency-native"
                select
                className={classes.inputField}
                label="Property Value"
                value={propValue}
                onChange={(event) => updatepropValue(event.target.value)}
                SelectProps={{
                  native: true,
                  'aria-describedby': 'ada-only-text'
                }}
                variant="outlined"
              >
                {propValue === 0 && <option>Property Value</option>}
                {rewardTable.map((option) => (
                  <option key={option.value ? option.value : option.min} value={option.value ? option.value : option.min}>
                    $
                    {option.value ? option.value : option.min}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
          {cashbackVisible
            && (LocState ? (
              <div>
                <Box id="ada-only-text" style={{ display: 'none' }}>
                  {data.bodyHelperText}
                  $
                  {cashback}
                </Box>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper className={classes.paper}>
                    <Typography style={{ textAlign: 'center' }}>
                      {data.bodyHelperText}
                    </Typography>
                    <Typography variant="h2" style={{ textAlign: 'center', paddingBottom: '0' }}>
                      <sup>$</sup>
                      {cashbakCounter ? (
                        <CountUp separator="," className={classes.counter} end={cashback} duration={2} />
                      ) : (
                        <Typography variant="h2" component="span" className={classes.cashback}>
                          {cashback.toLocaleString()}
                        </Typography>
                      )}
                    </Typography>
                  </Paper>
                </Grid>
              </div>
            ) : '')}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper className={clsx(classes.paper, classes.typoAlign)} style={{ padding: '0px' }}>
              <Button id="estimated-sell-value-next-btn" disabled={nextDisable} onClick={() => handlerClick(routerHandling(context, 'next'))} variant="contained" size="large" className={clsx(!nextDisable ? globalThemeCss.containedBtn : '', classes.nextButton)}>
                Next
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div>
              <Paper className={clsx(classes.paper, classes.typoAlign)}>
                <Button id="estimated-sell-value-disclaimer" className={classes.haveQuestions} onClick={showDisclaimer}>Reward Disclaimer</Button>
              </Paper>
            </div>
          </Grid>
        </div>
      </>
      )}
      </Grid>
    </Container>
  );
}
